import { User } from '../generated/graphql';
import Config from '../services/Config';


export const getDeparmentName = (id: string, upper: boolean = true): string => {
    const name = departmentID2Name(id.toLowerCase())
    if (!upper) {
        return name
    }
    return name.toUpperCase()
}

/**
* @param departmentName
*
* given the user type id returns the name in portuguese
*/
export const departmentID2Name = (id: string): string => {
    const names: any = {
        'commercial': 'comercial',
        'cad': 'cad',
        'cutting': 'corte',
        'confection': 'confeção',
        'ornaments': 'ornamentos',
        'finishings': 'acabamentos',
        'control': 'controlo'
    }
    return names[id]

}

export const department2state = (department: string) => {
    const sampleStateTypes: any = {
        'commercial': 1,
        'cad': 2,
        'cutting': 3,
        'confection': 4,
        'ornaments': 5,
        'finishings': 6,
        'control': 7
    }
    return sampleStateTypes[department]
}

export const mediaUrl = (rel_url: string) => {
    return Config.MEDIA_URL + rel_url;
}

/**
* returns the difference between now and the provided date
*
* @param date - Date object or string (which will be converted in date)
* see: https://stackoverflow.com/a/60688789
*/
export const diffDateNow = (date: Date | string): number => {

    const now = new Date();

    if (typeof date === 'string') {
        date = new Date(date);
    }

    return now.valueOf() - date.valueOf();
}

/**
* returns true if the provided date is in the past
*
*
* @param date - Date object or string (which will be converted in date)
* see: https://stackoverflow.com/a/60688789
*/
export const isPassedDate = (date: Date | string): Boolean => {

    if (!date) {
        return false;
    }

    const now = new Date()

    if (typeof date === 'string') {
        date = new Date(date);
    }

    return (date.valueOf() - now.valueOf()) < 0;
}

/** converts from microseconds to hours and minutes */
export const durationFormatter = (microseconds: number): string => {
    const seconds = microseconds / 1000;
    const m = seconds / 60;
    const hours = Math.floor(m / 60);
    const minutes = String(Math.ceil(m % 60));

    if (!hours) {
        return minutes === "1" ? `${minutes} MIN` : `${minutes} MIN`;
    }

    const paddedMinutes = minutes.padStart(2, '0');

    return `${hours}h${paddedMinutes}`
}

/**
* truncates a string at the required length
*
* @param str - the string to truncate
* @param chars - the length at which the string will be truncated (will not take in account the ellipsis)
* @param ellipsis - apply ellipsis to the truncated string (the returned string will be longer than chars value)
*/
export const truncateString = (str: string, chars: number, ellipsis?: boolean): string => {
    if (str.length <= chars) {
        return str
    }
    const sliced = str.slice(0, chars)
    return ellipsis ? sliced + '...' : sliced
}

/**
*
* returns the user color or the default color if the user does not have one
*
* @param user - a user
*
*/
export const userColor = (user: User | undefined | null) => {
    return user?.color ? user.color : '#ccc'
}

/**
*
*
*
*/
export const parseDepartmentUrl = (url: string): string => {
    return url.substring(1).toUpperCase()
}
