import Grid from '@material-ui/core/Grid';
import React, { FC } from 'react';
import today from '../../assets/images/today.png';
import week from '../../assets/images/week.png';
import yesterday from '../../assets/images/yesterday.png';
import { UserStatFragment } from '../../generated/graphql';
import Avatar from '../Avatar/Avatar';
import styles from './DashboardSummaryStats.module.scss';

const SummaryStat = ({ stat }: { stat: any }) => {
    return (
        <Grid container alignItems="center" className={styles.SummaryStat}>
            <Grid item className={styles.AvatarContainer}>
                <div>
                    <Avatar name={stat.name} color={stat.color} avatar={stat.avatar} width={45} height={45} />
                </div>
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    <Grid container className={styles.StatLine}>
                        <img src={week} className={styles.Icon} alt="week" />
                        <span className={styles.First}>{stat?.stats?.week?.current}</span>
                        <span className={styles.Second}>({stat?.stats?.week?.reference})</span>
                    </Grid>
                    <Grid container className={styles.StatLine}>
                        <img src={yesterday} className={styles.Icon} alt="yesterday" />
                        <span className={styles.First}>{stat?.stats?.day?.reference}</span>
                    </Grid>
                    <Grid container className={styles.StatLine}>
                        <img src={today} className={styles.Icon} alt="today" />
                        <span className={styles.First}>{stat?.stats?.day?.current}</span>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

interface DashboardSummaryProps {
    users?: UserStatFragment[]
}

const DashboardSummaryStats: FC<DashboardSummaryProps> = (props) => {

    return (
        <Grid container justify="space-around" className={styles.DashboardSummaryStats} >
            {
                props?.users && props?.users.map((stat: any) => (
                    <Grid item xs={4} key={Math.random()} >
                        <SummaryStat stat={stat} />
                    </Grid>
                )
                )}
        </Grid >
    )
}


export default DashboardSummaryStats;
