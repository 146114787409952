import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** Cursor for paging through collections */
  ConnectionCursor: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type SampleStateType = {
  __typename?: 'SampleStateType';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
  order: Scalars['Float'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  avatar: Scalars['String'];
  priority: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  commercial?: Maybe<User>;
  stylist?: Maybe<User>;
};


export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MeshConsumption = {
  __typename?: 'MeshConsumption';
  id: Scalars['Float'];
  quantity: Scalars['Float'];
  purpose?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
  mesh: Mesh;
};

export type Supplier = {
  __typename?: 'Supplier';
  id: Scalars['ID'];
  name: Scalars['String'];
  isSupplier: Scalars['Boolean'];
  isMeshSupplier: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type Mesh = {
  __typename?: 'Mesh';
  id: Scalars['Float'];
  supplierDocument: Scalars['String'];
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder: Scalars['String'];
  purchaseOrderType: Scalars['String'];
  meshKind: Scalars['String'];
  composition: Scalars['String'];
  color: Scalars['String'];
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived: Scalars['Float'];
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived: Scalars['Float'];
  washType?: Maybe<Scalars['String']>;
  widthBeforeWash?: Maybe<Scalars['Float']>;
  widthAfterWash?: Maybe<Scalars['Float']>;
  widthShrinkageObservations?: Maybe<Scalars['String']>;
  lengthBeforeWash?: Maybe<Scalars['Float']>;
  lengthAfterWash?: Maybe<Scalars['Float']>;
  lengthShrinkageObservations?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spiralityObservations?: Maybe<Scalars['String']>;
  quantityUnit: Scalars['String'];
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived: Scalars['Float'];
  currentQuantity?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  inspectionDate: Scalars['DateTime'];
  receptionDate: Scalars['DateTime'];
  inspectedBy: User;
  brand: Brand;
  supplier: Supplier;
  meshSupplier?: Maybe<Supplier>;
  location?: Maybe<Location>;
  meshConsumptions?: Maybe<Array<MeshConsumption>>;
  sampleMeshes?: Maybe<Array<SampleMesh>>;
};


export type MeshMeshConsumptionsArgs = {
  filter?: Maybe<MeshConsumptionFilter>;
  sorting?: Maybe<Array<MeshConsumptionSort>>;
};


export type MeshSampleMeshesArgs = {
  filter?: Maybe<SampleMeshFilter>;
  sorting?: Maybe<Array<SampleMeshSort>>;
};

export type MeshConsumptionFilter = {
  and?: Maybe<Array<MeshConsumptionFilter>>;
  or?: Maybe<Array<MeshConsumptionFilter>>;
  id?: Maybe<NumberFieldComparison>;
  quantity?: Maybe<NumberFieldComparison>;
  purpose?: Maybe<StringFieldComparison>;
};

export type NumberFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  between?: Maybe<NumberFieldComparisonBetween>;
  notBetween?: Maybe<NumberFieldComparisonBetween>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type StringFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  like?: Maybe<Scalars['String']>;
  notLike?: Maybe<Scalars['String']>;
  iLike?: Maybe<Scalars['String']>;
  notILike?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
};

export type MeshConsumptionSort = {
  field: MeshConsumptionSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum MeshConsumptionSortFields {
  Id = 'id',
  Quantity = 'quantity',
  Purpose = 'purpose'
}

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type SampleMeshFilter = {
  and?: Maybe<Array<SampleMeshFilter>>;
  or?: Maybe<Array<SampleMeshFilter>>;
  id?: Maybe<NumberFieldComparison>;
};

export type SampleMeshSort = {
  field: SampleMeshSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SampleMeshSortFields {
  Id = 'id'
}

export type SampleMesh = {
  __typename?: 'SampleMesh';
  id: Scalars['Float'];
  mesh: Mesh;
  sample: Sample;
};

export type SamplePhoto = {
  __typename?: 'SamplePhoto';
  id: Scalars['ID'];
  photo: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sample: Sample;
};

export type SampleType = {
  __typename?: 'SampleType';
  id: Scalars['ID'];
  name: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
};

export type Sample = {
  __typename?: 'Sample';
  id: Scalars['Float'];
  orderReference?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  foreseenDate?: Maybe<Scalars['DateTime']>;
  intendedDate: Scalars['DateTime'];
  sketch: Scalars['String'];
  fastTrack: Scalars['Boolean'];
  hasMesh: Scalars['Boolean'];
  hasMeshUpdatedAt: Scalars['String'];
  shippingDate: Scalars['String'];
  hasConfectionAccessories?: Maybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt: Scalars['String'];
  hasPackingAccessories?: Maybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt: Scalars['String'];
  decorationsReceivedAt: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  isVirtual: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  foreseenDateExceededReason: Scalars['String'];
  quantity: Scalars['Float'];
  currentState: SampleState;
  confectionOverseer?: Maybe<User>;
  sampleType: SampleType;
  brand: Brand;
  samplePhotos: Array<SamplePhoto>;
};


export type SampleSamplePhotosArgs = {
  filter?: Maybe<SamplePhotoFilter>;
  sorting?: Maybe<Array<SamplePhotoSort>>;
};

export type SamplePhotoFilter = {
  and?: Maybe<Array<SamplePhotoFilter>>;
  or?: Maybe<Array<SamplePhotoFilter>>;
  id?: Maybe<IdFilterComparison>;
  photo?: Maybe<StringFieldComparison>;
};

export type IdFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['ID']>;
  neq?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  like?: Maybe<Scalars['ID']>;
  notLike?: Maybe<Scalars['ID']>;
  iLike?: Maybe<Scalars['ID']>;
  notILike?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  notIn?: Maybe<Array<Scalars['ID']>>;
};

export type SamplePhotoSort = {
  field: SamplePhotoSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SamplePhotoSortFields {
  Id = 'id',
  Photo = 'photo'
}

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['ID'];
  name: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  todo: Array<Sample>;
  doing: Array<Sample>;
  done: Array<Sample>;
  todoCount: Scalars['Int'];
  doingCount: Scalars['Int'];
  doneCount: Scalars['Int'];
  users: Array<User>;
};


export type UserTypeUsersArgs = {
  filter?: Maybe<UserFilter>;
  sorting?: Maybe<Array<UserSort>>;
};

export type UserFilter = {
  and?: Maybe<Array<UserFilter>>;
  or?: Maybe<Array<UserFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type UserSort = {
  field: UserSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum UserSortFields {
  Id = 'id',
  Name = 'name'
}

export type DashPeriodStat = {
  __typename?: 'DashPeriodStat';
  reference: Scalars['Float'];
  current: Scalars['Float'];
};

export type DashUserStat = {
  __typename?: 'DashUserStat';
  week: DashPeriodStat;
  day: DashPeriodStat;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  type: UserType;
  stats: DashUserStat;
  checkinSampleStates: Array<SampleState>;
};


export type UserCheckinSampleStatesArgs = {
  filter?: Maybe<SampleStateFilter>;
  sorting?: Maybe<Array<SampleStateSort>>;
};

export type SampleStateFilter = {
  and?: Maybe<Array<SampleStateFilter>>;
  or?: Maybe<Array<SampleStateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  sampleStateTypeId?: Maybe<NumberFieldComparison>;
  sampleStateType?: Maybe<SampleStateFilterSampleStateTypeFilter>;
};

export type DateFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  between?: Maybe<DateFieldComparisonBetween>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};

export type SampleStateFilterSampleStateTypeFilter = {
  and?: Maybe<Array<SampleStateFilterSampleStateTypeFilter>>;
  or?: Maybe<Array<SampleStateFilterSampleStateTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
};

export type SampleStateSort = {
  field: SampleStateSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SampleStateSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  SampleStateTypeId = 'sampleStateTypeId'
}

export type SampleState = {
  __typename?: 'SampleState';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sampleStateTypeId: Scalars['Float'];
  sampleStateType: SampleStateType;
  checkinUser?: Maybe<User>;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int'];
};

export type SampleStateDeleteResponse = {
  __typename?: 'SampleStateDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int'];
};

export type SampleStateEdge = {
  __typename?: 'SampleStateEdge';
  /** The node containing the SampleState */
  node: SampleState;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};


export type PageInfo = {
  __typename?: 'PageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
};

export type SampleStateConnection = {
  __typename?: 'SampleStateConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<SampleStateEdge>;
};

export type SampleStateAggregateGroupBy = {
  __typename?: 'SampleStateAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateCountAggregate = {
  __typename?: 'SampleStateCountAggregate';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  sampleStateTypeId?: Maybe<Scalars['Int']>;
};

export type SampleStateSumAggregate = {
  __typename?: 'SampleStateSumAggregate';
  id?: Maybe<Scalars['Float']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateAvgAggregate = {
  __typename?: 'SampleStateAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateMinAggregate = {
  __typename?: 'SampleStateMinAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type SampleStateMaxAggregate = {
  __typename?: 'SampleStateMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
  stats?: Maybe<DashUserStat>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** The node containing the User */
  node: User;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<UserEdge>;
};

export type UserAggregateGroupBy = {
  __typename?: 'UserAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BrandDeleteResponse = {
  __typename?: 'BrandDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  commercial?: Maybe<User>;
  stylist?: Maybe<User>;
};

export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** The node containing the Brand */
  node: Brand;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type BrandConnection = {
  __typename?: 'BrandConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<BrandEdge>;
};

export type BrandAggregateGroupBy = {
  __typename?: 'BrandAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type BrandCountAggregate = {
  __typename?: 'BrandCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Int']>;
};

export type BrandSumAggregate = {
  __typename?: 'BrandSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type BrandAvgAggregate = {
  __typename?: 'BrandAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type BrandMinAggregate = {
  __typename?: 'BrandMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BrandMaxAggregate = {
  __typename?: 'BrandMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Checklist = {
  __typename?: 'Checklist';
  id: Scalars['Float'];
  name: Scalars['String'];
  event: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userType: UserType;
  checklistItems?: Maybe<Array<ChecklistItem>>;
};


export type ChecklistChecklistItemsArgs = {
  filter?: Maybe<ChecklistItemFilter>;
  sorting?: Maybe<Array<ChecklistItemSort>>;
};

export type ChecklistItemFilter = {
  and?: Maybe<Array<ChecklistItemFilter>>;
  or?: Maybe<Array<ChecklistItemFilter>>;
  name?: Maybe<StringFieldComparison>;
};

export type ChecklistItemSort = {
  field: ChecklistItemSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ChecklistItemSortFields {
  Name = 'name'
}

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  id: Scalars['Float'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ChecklistItemEdge = {
  __typename?: 'ChecklistItemEdge';
  /** The node containing the ChecklistItem */
  node: ChecklistItem;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ChecklistItemConnection = {
  __typename?: 'ChecklistItemConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ChecklistItemEdge>;
};

export type ChecklistItemAggregateGroupBy = {
  __typename?: 'ChecklistItemAggregateGroupBy';
  name?: Maybe<Scalars['String']>;
};

export type ChecklistItemCountAggregate = {
  __typename?: 'ChecklistItemCountAggregate';
  name?: Maybe<Scalars['Int']>;
};

export type ChecklistItemMinAggregate = {
  __typename?: 'ChecklistItemMinAggregate';
  name?: Maybe<Scalars['String']>;
};

export type ChecklistItemMaxAggregate = {
  __typename?: 'ChecklistItemMaxAggregate';
  name?: Maybe<Scalars['String']>;
};

export type ChecklistEdge = {
  __typename?: 'ChecklistEdge';
  /** The node containing the Checklist */
  node: Checklist;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ChecklistConnection = {
  __typename?: 'ChecklistConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ChecklistEdge>;
};

export type ChecklistAggregateGroupBy = {
  __typename?: 'ChecklistAggregateGroupBy';
  event?: Maybe<Scalars['String']>;
};

export type ChecklistCountAggregate = {
  __typename?: 'ChecklistCountAggregate';
  event?: Maybe<Scalars['Int']>;
};

export type ChecklistMinAggregate = {
  __typename?: 'ChecklistMinAggregate';
  event?: Maybe<Scalars['String']>;
};

export type ChecklistMaxAggregate = {
  __typename?: 'ChecklistMaxAggregate';
  event?: Maybe<Scalars['String']>;
};

export type CommonIssue = {
  __typename?: 'CommonIssue';
  id: Scalars['Float'];
  key: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommonIssueEdge = {
  __typename?: 'CommonIssueEdge';
  /** The node containing the CommonIssue */
  node: CommonIssue;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type CommonIssueConnection = {
  __typename?: 'CommonIssueConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<CommonIssueEdge>;
};

export type CommonIssueAggregateGroupBy = {
  __typename?: 'CommonIssueAggregateGroupBy';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CommonIssueCountAggregate = {
  __typename?: 'CommonIssueCountAggregate';
  key?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type CommonIssueMinAggregate = {
  __typename?: 'CommonIssueMinAggregate';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CommonIssueMaxAggregate = {
  __typename?: 'CommonIssueMaxAggregate';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Issue = {
  __typename?: 'Issue';
  id: Scalars['Float'];
  reference: Scalars['String'];
  order: Scalars['String'];
  description: Scalars['String'];
  sample: Scalars['String'];
};

export type LocationEdge = {
  __typename?: 'LocationEdge';
  /** The node containing the Location */
  node: Location;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type LocationConnection = {
  __typename?: 'LocationConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<LocationEdge>;
};

export type LocationAggregateGroupBy = {
  __typename?: 'LocationAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LocationCountAggregate = {
  __typename?: 'LocationCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type LocationSumAggregate = {
  __typename?: 'LocationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type LocationAvgAggregate = {
  __typename?: 'LocationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type LocationMinAggregate = {
  __typename?: 'LocationMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LocationMaxAggregate = {
  __typename?: 'LocationMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type MeshConnection = {
  __typename?: 'MeshConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Mesh>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type MeshAggregateGroupBy = {
  __typename?: 'MeshAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  supplierDocument?: Maybe<Scalars['String']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  meshKind?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  washType?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  quantityUnit?: Maybe<Scalars['String']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type MeshCountAggregate = {
  __typename?: 'MeshCountAggregate';
  id?: Maybe<Scalars['Int']>;
  supplierDocument?: Maybe<Scalars['Int']>;
  meshSupplierDocument?: Maybe<Scalars['Int']>;
  purchaseOrder?: Maybe<Scalars['Int']>;
  meshKind?: Maybe<Scalars['Int']>;
  composition?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['Int']>;
  colorObservations?: Maybe<Scalars['Int']>;
  widthRequired?: Maybe<Scalars['Int']>;
  widthReceived?: Maybe<Scalars['Int']>;
  weightRequired?: Maybe<Scalars['Int']>;
  weightReceived?: Maybe<Scalars['Int']>;
  washType?: Maybe<Scalars['Int']>;
  spiralityDeviation?: Maybe<Scalars['Int']>;
  quantityUnit?: Maybe<Scalars['Int']>;
  quantitySent?: Maybe<Scalars['Int']>;
  quantityReceived?: Maybe<Scalars['Int']>;
  currentQuantity?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
};

export type MeshSumAggregate = {
  __typename?: 'MeshSumAggregate';
  id?: Maybe<Scalars['Float']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  currentQuantity?: Maybe<Scalars['Float']>;
};

export type MeshAvgAggregate = {
  __typename?: 'MeshAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  currentQuantity?: Maybe<Scalars['Float']>;
};

export type MeshMinAggregate = {
  __typename?: 'MeshMinAggregate';
  id?: Maybe<Scalars['Float']>;
  supplierDocument?: Maybe<Scalars['String']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  meshKind?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  washType?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  quantityUnit?: Maybe<Scalars['String']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type MeshMaxAggregate = {
  __typename?: 'MeshMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  supplierDocument?: Maybe<Scalars['String']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  meshKind?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  washType?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  quantityUnit?: Maybe<Scalars['String']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  currentQuantity?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type MeshConsumptionEdge = {
  __typename?: 'MeshConsumptionEdge';
  /** The node containing the MeshConsumption */
  node: MeshConsumption;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type MeshConsumptionConnection = {
  __typename?: 'MeshConsumptionConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<MeshConsumptionEdge>;
};

export type MeshConsumptionAggregateGroupBy = {
  __typename?: 'MeshConsumptionAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
};

export type MeshConsumptionCountAggregate = {
  __typename?: 'MeshConsumptionCountAggregate';
  id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  purpose?: Maybe<Scalars['Int']>;
};

export type MeshConsumptionSumAggregate = {
  __typename?: 'MeshConsumptionSumAggregate';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type MeshConsumptionAvgAggregate = {
  __typename?: 'MeshConsumptionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type MeshConsumptionMinAggregate = {
  __typename?: 'MeshConsumptionMinAggregate';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
};

export type MeshConsumptionMaxAggregate = {
  __typename?: 'MeshConsumptionMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
};

export type SampleMeshDeleteResponse = {
  __typename?: 'SampleMeshDeleteResponse';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshEdge = {
  __typename?: 'SampleMeshEdge';
  /** The node containing the SampleMesh */
  node: SampleMesh;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type SampleMeshConnection = {
  __typename?: 'SampleMeshConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<SampleMeshEdge>;
};

export type SampleMeshAggregateGroupBy = {
  __typename?: 'SampleMeshAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshCountAggregate = {
  __typename?: 'SampleMeshCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type SampleMeshSumAggregate = {
  __typename?: 'SampleMeshSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshAvgAggregate = {
  __typename?: 'SampleMeshAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshMinAggregate = {
  __typename?: 'SampleMeshMinAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleMeshMaxAggregate = {
  __typename?: 'SampleMeshMaxAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SamplePhotoEdge = {
  __typename?: 'SamplePhotoEdge';
  /** The node containing the SamplePhoto */
  node: SamplePhoto;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type SamplePhotoAggregateGroupBy = {
  __typename?: 'SamplePhotoAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  photo?: Maybe<Scalars['String']>;
};

export type SamplePhotoCountAggregate = {
  __typename?: 'SamplePhotoCountAggregate';
  id?: Maybe<Scalars['Int']>;
  photo?: Maybe<Scalars['Int']>;
};

export type SamplePhotoSumAggregate = {
  __typename?: 'SamplePhotoSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SamplePhotoAvgAggregate = {
  __typename?: 'SamplePhotoAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SamplePhotoMinAggregate = {
  __typename?: 'SamplePhotoMinAggregate';
  id?: Maybe<Scalars['ID']>;
  photo?: Maybe<Scalars['String']>;
};

export type SamplePhotoMaxAggregate = {
  __typename?: 'SamplePhotoMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  photo?: Maybe<Scalars['String']>;
};

export type SampleTypeDeleteResponse = {
  __typename?: 'SampleTypeDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type SampleTypeEdge = {
  __typename?: 'SampleTypeEdge';
  /** The node containing the SampleType */
  node: SampleType;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type SampleTypeConnection = {
  __typename?: 'SampleTypeConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<SampleTypeEdge>;
};

export type SampleTypeAggregateGroupBy = {
  __typename?: 'SampleTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SampleTypeCountAggregate = {
  __typename?: 'SampleTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type SampleTypeSumAggregate = {
  __typename?: 'SampleTypeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleTypeAvgAggregate = {
  __typename?: 'SampleTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SampleTypeMinAggregate = {
  __typename?: 'SampleTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SampleTypeMaxAggregate = {
  __typename?: 'SampleTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SampleDeleteResponse = {
  __typename?: 'SampleDeleteResponse';
  id?: Maybe<Scalars['Float']>;
  orderReference?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  foreseenDate?: Maybe<Scalars['DateTime']>;
  intendedDate?: Maybe<Scalars['DateTime']>;
  sketch?: Maybe<Scalars['String']>;
  fastTrack?: Maybe<Scalars['Boolean']>;
  hasMesh?: Maybe<Scalars['Boolean']>;
  hasMeshUpdatedAt?: Maybe<Scalars['String']>;
  shippingDate?: Maybe<Scalars['String']>;
  hasConfectionAccessories?: Maybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  hasPackingAccessories?: Maybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  decorationsReceivedAt?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  foreseenDateExceededReason?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleEdge = {
  __typename?: 'SampleEdge';
  /** The node containing the Sample */
  node: Sample;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type SampleConnection = {
  __typename?: 'SampleConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<SampleEdge>;
};

export type SampleAggregateGroupBy = {
  __typename?: 'SampleAggregateGroupBy';
  orderReference?: Maybe<Scalars['Float']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleCountAggregate = {
  __typename?: 'SampleCountAggregate';
  orderReference?: Maybe<Scalars['Int']>;
  isVirtual?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type SampleSumAggregate = {
  __typename?: 'SampleSumAggregate';
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleAvgAggregate = {
  __typename?: 'SampleAvgAggregate';
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleMinAggregate = {
  __typename?: 'SampleMinAggregate';
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SampleMaxAggregate = {
  __typename?: 'SampleMaxAggregate';
  orderReference?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SupplierEdge = {
  __typename?: 'SupplierEdge';
  /** The node containing the Supplier */
  node: Supplier;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type SupplierConnection = {
  __typename?: 'SupplierConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<SupplierEdge>;
};

export type SupplierAggregateGroupBy = {
  __typename?: 'SupplierAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  isMeshSupplier?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SupplierCountAggregate = {
  __typename?: 'SupplierCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  isSupplier?: Maybe<Scalars['Int']>;
  isMeshSupplier?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
};

export type SupplierSumAggregate = {
  __typename?: 'SupplierSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SupplierAvgAggregate = {
  __typename?: 'SupplierAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SupplierMinAggregate = {
  __typename?: 'SupplierMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SupplierMaxAggregate = {
  __typename?: 'SupplierMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SupportTopic = {
  __typename?: 'SupportTopic';
  id: Scalars['Float'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  loom?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SupportTopicEdge = {
  __typename?: 'SupportTopicEdge';
  /** The node containing the SupportTopic */
  node: SupportTopic;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type SupportTopicConnection = {
  __typename?: 'SupportTopicConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<SupportTopicEdge>;
};

export type SupportTopicAggregateGroupBy = {
  __typename?: 'SupportTopicAggregateGroupBy';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SupportTopicCountAggregate = {
  __typename?: 'SupportTopicCountAggregate';
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type SupportTopicMinAggregate = {
  __typename?: 'SupportTopicMinAggregate';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SupportTopicMaxAggregate = {
  __typename?: 'SupportTopicMaxAggregate';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Ticket = {
  __typename?: 'Ticket';
  id: Scalars['Float'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  isPublic: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  ticketComments?: Maybe<Array<TicketComment>>;
  ticketFiles?: Maybe<Array<TicketFile>>;
};


export type TicketTicketCommentsArgs = {
  filter?: Maybe<TicketCommentFilter>;
  sorting?: Maybe<Array<TicketCommentSort>>;
};


export type TicketTicketFilesArgs = {
  filter?: Maybe<TicketFileFilter>;
  sorting?: Maybe<Array<TicketFileSort>>;
};

export type TicketCommentFilter = {
  and?: Maybe<Array<TicketCommentFilter>>;
  or?: Maybe<Array<TicketCommentFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type TicketCommentSort = {
  field: TicketCommentSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TicketCommentSortFields {
  CreatedAt = 'createdAt'
}

export type TicketFileFilter = {
  and?: Maybe<Array<TicketFileFilter>>;
  or?: Maybe<Array<TicketFileFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type TicketFileSort = {
  field: TicketFileSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TicketFileSortFields {
  CreatedAt = 'createdAt'
}

export type TicketComment = {
  __typename?: 'TicketComment';
  id: Scalars['Float'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  ticketFiles?: Maybe<Array<TicketFile>>;
};


export type TicketCommentTicketFilesArgs = {
  filter?: Maybe<TicketFileFilter>;
  sorting?: Maybe<Array<TicketFileSort>>;
};

export type TicketFile = {
  __typename?: 'TicketFile';
  id: Scalars['Float'];
  file: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TicketCommentEdge = {
  __typename?: 'TicketCommentEdge';
  /** The node containing the TicketComment */
  node: TicketComment;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type TicketCommentConnection = {
  __typename?: 'TicketCommentConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<TicketCommentEdge>;
};

export type TicketCommentAggregateGroupBy = {
  __typename?: 'TicketCommentAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TicketCommentCountAggregate = {
  __typename?: 'TicketCommentCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
};

export type TicketCommentMinAggregate = {
  __typename?: 'TicketCommentMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TicketCommentMaxAggregate = {
  __typename?: 'TicketCommentMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TicketEdge = {
  __typename?: 'TicketEdge';
  /** The node containing the Ticket */
  node: Ticket;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type TicketConnection = {
  __typename?: 'TicketConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<TicketEdge>;
};

export type TicketAggregateGroupBy = {
  __typename?: 'TicketAggregateGroupBy';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type TicketCountAggregate = {
  __typename?: 'TicketCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  isPublic?: Maybe<Scalars['Int']>;
};

export type TicketSumAggregate = {
  __typename?: 'TicketSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type TicketAvgAggregate = {
  __typename?: 'TicketAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type TicketMinAggregate = {
  __typename?: 'TicketMinAggregate';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type TicketMaxAggregate = {
  __typename?: 'TicketMaxAggregate';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type UserTypeDeleteResponse = {
  __typename?: 'UserTypeDeleteResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  todo?: Maybe<Array<Sample>>;
  doing?: Maybe<Array<Sample>>;
  done?: Maybe<Array<Sample>>;
  todoCount?: Maybe<Scalars['Int']>;
  doingCount?: Maybe<Scalars['Int']>;
  doneCount?: Maybe<Scalars['Int']>;
};

export type UserTypeEdge = {
  __typename?: 'UserTypeEdge';
  /** The node containing the UserType */
  node: UserType;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type UserTypeConnection = {
  __typename?: 'UserTypeConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<UserTypeEdge>;
};

export type UserTypeAggregateGroupBy = {
  __typename?: 'UserTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserTypeCountAggregate = {
  __typename?: 'UserTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type UserTypeMinAggregate = {
  __typename?: 'UserTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserTypeMaxAggregate = {
  __typename?: 'UserTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BrandInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  avatar: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  commercial: UserInput;
  stylist: UserInput;
};

export type SupplierInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  isSupplier: Scalars['Boolean'];
  isMeshSupplier: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type SampleTypeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
};

export type SampleInputType = {
  id: Scalars['Float'];
  orderReference?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  foreseenDate?: Maybe<Scalars['DateTime']>;
  intendedDate: Scalars['DateTime'];
  sketch: Scalars['String'];
  fastTrack: Scalars['Boolean'];
  hasMesh: Scalars['Boolean'];
  hasMeshUpdatedAt: Scalars['String'];
  shippingDate: Scalars['String'];
  hasConfectionAccessories?: Maybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt: Scalars['String'];
  hasPackingAccessories?: Maybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt: Scalars['String'];
  decorationsReceivedAt: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  isVirtual: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  foreseenDateExceededReason: Scalars['String'];
  quantity: Scalars['Float'];
};

export type UserTypeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  todo: Array<SampleInputType>;
  doing: Array<SampleInputType>;
  done: Array<SampleInputType>;
  todoCount: Scalars['Int'];
  doingCount: Scalars['Int'];
  doneCount: Scalars['Int'];
};

export type DashPeriodStatInput = {
  reference: Scalars['Float'];
  current: Scalars['Float'];
};

export type DashUserStatInput = {
  week: DashPeriodStatInput;
  day: DashPeriodStatInput;
};

export type UserInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  type?: Maybe<UserTypeInput>;
  stats: DashUserStatInput;
};

export type Query = {
  __typename?: 'Query';
  user?: Maybe<User>;
  users: UserConnection;
  me: User;
  sampleState?: Maybe<SampleState>;
  sampleStates: SampleStateConnection;
  brand?: Maybe<Brand>;
  brands: BrandConnection;
  checklist?: Maybe<Checklist>;
  checklists: ChecklistConnection;
  checklistItem?: Maybe<ChecklistItem>;
  checklistItems: ChecklistItemConnection;
  commonIssues: CommonIssueConnection;
  location?: Maybe<Location>;
  locations: LocationConnection;
  mesh?: Maybe<Mesh>;
  meshes: MeshConnection;
  meshConsumption?: Maybe<MeshConsumption>;
  meshConsumptions: MeshConsumptionConnection;
  sampleType?: Maybe<SampleType>;
  sampleTypes: SampleTypeConnection;
  sample?: Maybe<Sample>;
  samples: SampleConnection;
  sampleMesh?: Maybe<SampleMesh>;
  sampleMeshes: SampleMeshConnection;
  supportTopic?: Maybe<SupportTopic>;
  supportTopics: SupportTopicConnection;
  userType?: Maybe<UserType>;
  userTypes: UserTypeConnection;
  supplier?: Maybe<Supplier>;
  suppliers: SupplierConnection;
  tickets: TicketConnection;
  ticket?: Maybe<Ticket>;
  ticketComment?: Maybe<TicketComment>;
  ticketComments: TicketCommentConnection;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<UserFilter>;
  sorting?: Maybe<Array<UserSort>>;
};


export type QuerySampleStateArgs = {
  id: Scalars['ID'];
};


export type QuerySampleStatesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<SampleStateFilter>;
  sorting?: Maybe<Array<SampleStateSort>>;
};


export type QueryBrandArgs = {
  id: Scalars['ID'];
};


export type QueryBrandsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<BrandFilter>;
  sorting?: Maybe<Array<BrandSort>>;
};


export type QueryChecklistArgs = {
  id: Scalars['ID'];
};


export type QueryChecklistsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<ChecklistFilter>;
  sorting?: Maybe<Array<ChecklistSort>>;
};


export type QueryChecklistItemArgs = {
  id: Scalars['ID'];
};


export type QueryChecklistItemsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<ChecklistItemFilter>;
  sorting?: Maybe<Array<ChecklistItemSort>>;
};


export type QueryCommonIssuesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<CommonIssueFilter>;
  sorting?: Maybe<Array<CommonIssueSort>>;
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
};


export type QueryLocationsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<LocationFilter>;
  sorting?: Maybe<Array<LocationSort>>;
};


export type QueryMeshArgs = {
  id: Scalars['ID'];
};


export type QueryMeshesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<MeshFilter>;
  sorting?: Maybe<Array<MeshSort>>;
};


export type QueryMeshConsumptionArgs = {
  id: Scalars['ID'];
};


export type QueryMeshConsumptionsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<MeshConsumptionFilter>;
  sorting?: Maybe<Array<MeshConsumptionSort>>;
};


export type QuerySampleTypeArgs = {
  id: Scalars['ID'];
};


export type QuerySampleTypesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<SampleTypeFilter>;
  sorting?: Maybe<Array<SampleTypeSort>>;
};


export type QuerySampleArgs = {
  id: Scalars['ID'];
};


export type QuerySamplesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<SampleFilter>;
  sorting?: Maybe<Array<SampleSort>>;
};


export type QuerySampleMeshArgs = {
  id: Scalars['ID'];
};


export type QuerySampleMeshesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<SampleMeshFilter>;
  sorting?: Maybe<Array<SampleMeshSort>>;
};


export type QuerySupportTopicArgs = {
  id: Scalars['ID'];
};


export type QuerySupportTopicsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<SupportTopicFilter>;
  sorting?: Maybe<Array<SupportTopicSort>>;
};


export type QueryUserTypeArgs = {
  id: Scalars['ID'];
};


export type QueryUserTypesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<UserTypeFilter>;
  sorting?: Maybe<Array<UserTypeSort>>;
};


export type QuerySupplierArgs = {
  id: Scalars['ID'];
};


export type QuerySuppliersArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<SupplierFilter>;
  sorting?: Maybe<Array<SupplierSort>>;
};


export type QueryTicketsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TicketFilter>;
  sorting?: Maybe<Array<TicketSort>>;
};


export type QueryTicketArgs = {
  id: Scalars['ID'];
};


export type QueryTicketCommentArgs = {
  id: Scalars['ID'];
};


export type QueryTicketCommentsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TicketCommentFilter>;
  sorting?: Maybe<Array<TicketCommentSort>>;
};

export type CursorPaging = {
  /** Paginate before opaque cursor */
  before?: Maybe<Scalars['ConnectionCursor']>;
  /** Paginate after opaque cursor */
  after?: Maybe<Scalars['ConnectionCursor']>;
  /** Paginate first */
  first?: Maybe<Scalars['Int']>;
  /** Paginate last */
  last?: Maybe<Scalars['Int']>;
};

export type BrandFilter = {
  and?: Maybe<Array<BrandFilter>>;
  or?: Maybe<Array<BrandFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
};

export type BooleanFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
};

export type BrandSort = {
  field: BrandSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum BrandSortFields {
  Id = 'id',
  Name = 'name',
  IsActive = 'isActive'
}

export type ChecklistFilter = {
  and?: Maybe<Array<ChecklistFilter>>;
  or?: Maybe<Array<ChecklistFilter>>;
  event?: Maybe<StringFieldComparison>;
};

export type ChecklistSort = {
  field: ChecklistSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ChecklistSortFields {
  Event = 'event'
}

export type CommonIssueFilter = {
  and?: Maybe<Array<CommonIssueFilter>>;
  or?: Maybe<Array<CommonIssueFilter>>;
  key?: Maybe<StringFieldComparison>;
  value?: Maybe<StringFieldComparison>;
};

export type CommonIssueSort = {
  field: CommonIssueSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum CommonIssueSortFields {
  Key = 'key',
  Value = 'value'
}

export type LocationFilter = {
  and?: Maybe<Array<LocationFilter>>;
  or?: Maybe<Array<LocationFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type LocationSort = {
  field: LocationSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum LocationSortFields {
  Id = 'id',
  Name = 'name'
}

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: Maybe<Scalars['Int']>;
  /** Offset to start returning records from */
  offset?: Maybe<Scalars['Int']>;
};

export type MeshFilter = {
  and?: Maybe<Array<MeshFilter>>;
  or?: Maybe<Array<MeshFilter>>;
  id?: Maybe<NumberFieldComparison>;
  supplierDocument?: Maybe<StringFieldComparison>;
  meshSupplierDocument?: Maybe<StringFieldComparison>;
  purchaseOrder?: Maybe<StringFieldComparison>;
  meshKind?: Maybe<StringFieldComparison>;
  composition?: Maybe<StringFieldComparison>;
  color?: Maybe<StringFieldComparison>;
  colorObservations?: Maybe<StringFieldComparison>;
  widthRequired?: Maybe<NumberFieldComparison>;
  widthReceived?: Maybe<NumberFieldComparison>;
  weightRequired?: Maybe<NumberFieldComparison>;
  weightReceived?: Maybe<NumberFieldComparison>;
  washType?: Maybe<StringFieldComparison>;
  spiralityDeviation?: Maybe<NumberFieldComparison>;
  quantityUnit?: Maybe<StringFieldComparison>;
  quantitySent?: Maybe<NumberFieldComparison>;
  quantityReceived?: Maybe<NumberFieldComparison>;
  currentQuantity?: Maybe<NumberFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  location?: Maybe<MeshFilterLocationFilter>;
  meshSupplier?: Maybe<MeshFilterSupplierFilter>;
  supplier?: Maybe<MeshFilterSupplierFilter>;
  brand?: Maybe<MeshFilterBrandFilter>;
  inspectedBy?: Maybe<MeshFilterUserFilter>;
};

export type MeshFilterLocationFilter = {
  and?: Maybe<Array<MeshFilterLocationFilter>>;
  or?: Maybe<Array<MeshFilterLocationFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type MeshFilterSupplierFilter = {
  and?: Maybe<Array<MeshFilterSupplierFilter>>;
  or?: Maybe<Array<MeshFilterSupplierFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isSupplier?: Maybe<BooleanFieldComparison>;
  isMeshSupplier?: Maybe<BooleanFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type MeshFilterBrandFilter = {
  and?: Maybe<Array<MeshFilterBrandFilter>>;
  or?: Maybe<Array<MeshFilterBrandFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
};

export type MeshFilterUserFilter = {
  and?: Maybe<Array<MeshFilterUserFilter>>;
  or?: Maybe<Array<MeshFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type MeshSort = {
  field: MeshSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum MeshSortFields {
  Id = 'id',
  SupplierDocument = 'supplierDocument',
  MeshSupplierDocument = 'meshSupplierDocument',
  PurchaseOrder = 'purchaseOrder',
  MeshKind = 'meshKind',
  Composition = 'composition',
  Color = 'color',
  ColorObservations = 'colorObservations',
  WidthRequired = 'widthRequired',
  WidthReceived = 'widthReceived',
  WeightRequired = 'weightRequired',
  WeightReceived = 'weightReceived',
  WashType = 'washType',
  SpiralityDeviation = 'spiralityDeviation',
  QuantityUnit = 'quantityUnit',
  QuantitySent = 'quantitySent',
  QuantityReceived = 'quantityReceived',
  CurrentQuantity = 'currentQuantity',
  CreatedAt = 'createdAt'
}

export type SampleTypeFilter = {
  and?: Maybe<Array<SampleTypeFilter>>;
  or?: Maybe<Array<SampleTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type SampleTypeSort = {
  field: SampleTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SampleTypeSortFields {
  Id = 'id',
  Name = 'name'
}

export type SampleFilter = {
  and?: Maybe<Array<SampleFilter>>;
  or?: Maybe<Array<SampleFilter>>;
  orderReference?: Maybe<NumberFieldComparison>;
  isVirtual?: Maybe<BooleanFieldComparison>;
  quantity?: Maybe<NumberFieldComparison>;
  currentState?: Maybe<SampleFilterSampleStateFilter>;
};

export type SampleFilterSampleStateFilter = {
  and?: Maybe<Array<SampleFilterSampleStateFilter>>;
  or?: Maybe<Array<SampleFilterSampleStateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  sampleStateTypeId?: Maybe<NumberFieldComparison>;
};

export type SampleSort = {
  field: SampleSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SampleSortFields {
  OrderReference = 'orderReference',
  IsVirtual = 'isVirtual',
  Quantity = 'quantity'
}

export type SupportTopicFilter = {
  and?: Maybe<Array<SupportTopicFilter>>;
  or?: Maybe<Array<SupportTopicFilter>>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  state?: Maybe<StringFieldComparison>;
  type?: Maybe<StringFieldComparison>;
};

export type SupportTopicSort = {
  field: SupportTopicSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SupportTopicSortFields {
  Name = 'name',
  Description = 'description',
  State = 'state',
  Type = 'type'
}

export type UserTypeFilter = {
  and?: Maybe<Array<UserTypeFilter>>;
  or?: Maybe<Array<UserTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type UserTypeSort = {
  field: UserTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum UserTypeSortFields {
  Id = 'id',
  Name = 'name'
}

export type SupplierFilter = {
  and?: Maybe<Array<SupplierFilter>>;
  or?: Maybe<Array<SupplierFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isSupplier?: Maybe<BooleanFieldComparison>;
  isMeshSupplier?: Maybe<BooleanFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type SupplierSort = {
  field: SupplierSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum SupplierSortFields {
  Id = 'id',
  Name = 'name',
  IsSupplier = 'isSupplier',
  IsMeshSupplier = 'isMeshSupplier',
  CreatedAt = 'createdAt'
}

export type TicketFilter = {
  and?: Maybe<Array<TicketFilter>>;
  or?: Maybe<Array<TicketFilter>>;
  id?: Maybe<NumberFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  description?: Maybe<StringFieldComparison>;
  state?: Maybe<StringFieldComparison>;
  isPublic?: Maybe<BooleanFieldComparison>;
  user?: Maybe<TicketFilterUserFilter>;
};

export type TicketFilterUserFilter = {
  and?: Maybe<Array<TicketFilterUserFilter>>;
  or?: Maybe<Array<TicketFilterUserFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type TicketSort = {
  field: TicketSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TicketSortFields {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  State = 'state',
  IsPublic = 'isPublic'
}

export type Mutation = {
  __typename?: 'Mutation';
  setTypeOnUser: User;
  addCheckinSampleStatesToUser: User;
  setCheckinSampleStatesOnUser: User;
  createOneUser: User;
  createManyUsers: Array<User>;
  updateOneUser: User;
  updateManyUsers: UpdateManyResponse;
  deleteOneUser: UserDeleteResponse;
  deleteManyUsers: DeleteManyResponse;
  setSampleStateTypeOnSampleState: SampleState;
  setCheckinUserOnSampleState: SampleState;
  createOneSampleState: SampleState;
  createManySampleStates: Array<SampleState>;
  updateOneSampleState: SampleState;
  updateManySampleStates: UpdateManyResponse;
  deleteOneSampleState: SampleStateDeleteResponse;
  deleteManySampleStates: DeleteManyResponse;
  setCommercialOnBrand: Brand;
  setStylistOnBrand: Brand;
  createOneBrand: Brand;
  createManyBrands: Array<Brand>;
  updateOneBrand: Brand;
  updateManyBrands: UpdateManyResponse;
  deleteOneBrand: BrandDeleteResponse;
  deleteManyBrands: DeleteManyResponse;
  setUserTypeOnChecklist: Checklist;
  addChecklistItemsToChecklist: Checklist;
  setChecklistItemsOnChecklist: Checklist;
  setInspectedByOnMesh: Mesh;
  setBrandOnMesh: Mesh;
  setSupplierOnMesh: Mesh;
  setMeshSupplierOnMesh: Mesh;
  setLocationOnMesh: Mesh;
  addMeshConsumptionsToMesh: Mesh;
  setMeshConsumptionsOnMesh: Mesh;
  addSampleMeshesToMesh: Mesh;
  setSampleMeshesOnMesh: Mesh;
  removeMeshConsumptionsFromMesh: Mesh;
  removeSampleMeshesFromMesh: Mesh;
  updateOneMesh: Mesh;
  updateManyMeshes: UpdateManyResponse;
  createOneMesh: Mesh;
  setUserOnMeshConsumption: MeshConsumption;
  setMeshOnMeshConsumption: MeshConsumption;
  updateOneMeshConsumption: MeshConsumption;
  updateManyMeshConsumptions: UpdateManyResponse;
  createOneMeshConsumption: MeshConsumption;
  deleteOneMeshConsumption: MeshConsumption;
  createOneSampleType: SampleType;
  createManySampleTypes: Array<SampleType>;
  updateOneSampleType: SampleType;
  updateManySampleTypes: UpdateManyResponse;
  deleteOneSampleType: SampleTypeDeleteResponse;
  deleteManySampleTypes: DeleteManyResponse;
  setCurrentStateOnSample: Sample;
  setConfectionOverseerOnSample: Sample;
  setSampleTypeOnSample: Sample;
  setBrandOnSample: Sample;
  addSamplePhotosToSample: Sample;
  setSamplePhotosOnSample: Sample;
  createOneSample: Sample;
  createManySamples: Array<Sample>;
  updateOneSample: Sample;
  updateManySamples: UpdateManyResponse;
  deleteOneSample: SampleDeleteResponse;
  deleteManySamples: DeleteManyResponse;
  setMeshOnSampleMesh: SampleMesh;
  setSampleOnSampleMesh: SampleMesh;
  createOneSampleMesh: SampleMesh;
  createManySampleMeshes: Array<SampleMesh>;
  updateOneSampleMesh: SampleMesh;
  updateManySampleMeshes: UpdateManyResponse;
  deleteOneSampleMesh: SampleMeshDeleteResponse;
  deleteManySampleMeshes: DeleteManyResponse;
  setSampleOnSamplePhoto: SamplePhoto;
  createOneSamplePhoto: SamplePhoto;
  addUsersToUserType: UserType;
  setUsersOnUserType: UserType;
  createOneUserType: UserType;
  createManyUserTypes: Array<UserType>;
  updateOneUserType: UserType;
  updateManyUserTypes: UpdateManyResponse;
  deleteOneUserType: UserTypeDeleteResponse;
  deleteManyUserTypes: DeleteManyResponse;
  setUserOnTicket: Ticket;
  addTicketCommentsToTicket: Ticket;
  setTicketCommentsOnTicket: Ticket;
  addTicketFilesToTicket: Ticket;
  setTicketFilesOnTicket: Ticket;
  createOneTicket: Ticket;
  setUserOnTicketComment: TicketComment;
  addTicketFilesToTicketComment: TicketComment;
  setTicketFilesOnTicketComment: TicketComment;
  createOneTicketComment: TicketComment;
  createOneIssue: Issue;
};


export type MutationSetTypeOnUserArgs = {
  input: SetTypeOnUserInput;
};


export type MutationAddCheckinSampleStatesToUserArgs = {
  input: AddCheckinSampleStatesToUserInput;
};


export type MutationSetCheckinSampleStatesOnUserArgs = {
  input: SetCheckinSampleStatesOnUserInput;
};


export type MutationCreateOneUserArgs = {
  input: CreateOneUserInput;
};


export type MutationCreateManyUsersArgs = {
  input: CreateManyUsersInput;
};


export type MutationUpdateOneUserArgs = {
  input: UpdateOneUserInput;
};


export type MutationUpdateManyUsersArgs = {
  input: UpdateManyUsersInput;
};


export type MutationDeleteOneUserArgs = {
  input: DeleteOneUserInput;
};


export type MutationDeleteManyUsersArgs = {
  input: DeleteManyUsersInput;
};


export type MutationSetSampleStateTypeOnSampleStateArgs = {
  input: SetSampleStateTypeOnSampleStateInput;
};


export type MutationSetCheckinUserOnSampleStateArgs = {
  input: SetCheckinUserOnSampleStateInput;
};


export type MutationCreateOneSampleStateArgs = {
  input: CreateOneSampleStateInput;
};


export type MutationCreateManySampleStatesArgs = {
  input: CreateManySampleStatesInput;
};


export type MutationUpdateOneSampleStateArgs = {
  input: UpdateOneSampleStateInput;
};


export type MutationUpdateManySampleStatesArgs = {
  input: UpdateManySampleStatesInput;
};


export type MutationDeleteOneSampleStateArgs = {
  input: DeleteOneSampleStateInput;
};


export type MutationDeleteManySampleStatesArgs = {
  input: DeleteManySampleStatesInput;
};


export type MutationSetCommercialOnBrandArgs = {
  input: SetCommercialOnBrandInput;
};


export type MutationSetStylistOnBrandArgs = {
  input: SetStylistOnBrandInput;
};


export type MutationCreateOneBrandArgs = {
  input: CreateOneBrandInput;
};


export type MutationCreateManyBrandsArgs = {
  input: CreateManyBrandsInput;
};


export type MutationUpdateOneBrandArgs = {
  input: UpdateOneBrandInput;
};


export type MutationUpdateManyBrandsArgs = {
  input: UpdateManyBrandsInput;
};


export type MutationDeleteOneBrandArgs = {
  input: DeleteOneBrandInput;
};


export type MutationDeleteManyBrandsArgs = {
  input: DeleteManyBrandsInput;
};


export type MutationSetUserTypeOnChecklistArgs = {
  input: SetUserTypeOnChecklistInput;
};


export type MutationAddChecklistItemsToChecklistArgs = {
  input: AddChecklistItemsToChecklistInput;
};


export type MutationSetChecklistItemsOnChecklistArgs = {
  input: SetChecklistItemsOnChecklistInput;
};


export type MutationSetInspectedByOnMeshArgs = {
  input: SetInspectedByOnMeshInput;
};


export type MutationSetBrandOnMeshArgs = {
  input: SetBrandOnMeshInput;
};


export type MutationSetSupplierOnMeshArgs = {
  input: SetSupplierOnMeshInput;
};


export type MutationSetMeshSupplierOnMeshArgs = {
  input: SetMeshSupplierOnMeshInput;
};


export type MutationSetLocationOnMeshArgs = {
  input: SetLocationOnMeshInput;
};


export type MutationAddMeshConsumptionsToMeshArgs = {
  input: AddMeshConsumptionsToMeshInput;
};


export type MutationSetMeshConsumptionsOnMeshArgs = {
  input: SetMeshConsumptionsOnMeshInput;
};


export type MutationAddSampleMeshesToMeshArgs = {
  input: AddSampleMeshesToMeshInput;
};


export type MutationSetSampleMeshesOnMeshArgs = {
  input: SetSampleMeshesOnMeshInput;
};


export type MutationRemoveMeshConsumptionsFromMeshArgs = {
  input: RemoveMeshConsumptionsFromMeshInput;
};


export type MutationRemoveSampleMeshesFromMeshArgs = {
  input: RemoveSampleMeshesFromMeshInput;
};


export type MutationUpdateOneMeshArgs = {
  input: UpdateOneMeshInput;
};


export type MutationUpdateManyMeshesArgs = {
  input: UpdateManyMeshesInput;
};


export type MutationCreateOneMeshArgs = {
  input: CreateOneMeshInput;
};


export type MutationSetUserOnMeshConsumptionArgs = {
  input: SetUserOnMeshConsumptionInput;
};


export type MutationSetMeshOnMeshConsumptionArgs = {
  input: SetMeshOnMeshConsumptionInput;
};


export type MutationUpdateOneMeshConsumptionArgs = {
  input: UpdateOneMeshConsumptionInput;
};


export type MutationUpdateManyMeshConsumptionsArgs = {
  input: UpdateManyMeshConsumptionsInput;
};


export type MutationCreateOneMeshConsumptionArgs = {
  input: CreateOneMeshConsumptionInput;
};


export type MutationDeleteOneMeshConsumptionArgs = {
  input: DeleteOneMeshConsumptionInput;
};


export type MutationCreateOneSampleTypeArgs = {
  input: CreateOneSampleTypeInput;
};


export type MutationCreateManySampleTypesArgs = {
  input: CreateManySampleTypesInput;
};


export type MutationUpdateOneSampleTypeArgs = {
  input: UpdateOneSampleTypeInput;
};


export type MutationUpdateManySampleTypesArgs = {
  input: UpdateManySampleTypesInput;
};


export type MutationDeleteOneSampleTypeArgs = {
  input: DeleteOneSampleTypeInput;
};


export type MutationDeleteManySampleTypesArgs = {
  input: DeleteManySampleTypesInput;
};


export type MutationSetCurrentStateOnSampleArgs = {
  input: SetCurrentStateOnSampleInput;
};


export type MutationSetConfectionOverseerOnSampleArgs = {
  input: SetConfectionOverseerOnSampleInput;
};


export type MutationSetSampleTypeOnSampleArgs = {
  input: SetSampleTypeOnSampleInput;
};


export type MutationSetBrandOnSampleArgs = {
  input: SetBrandOnSampleInput;
};


export type MutationAddSamplePhotosToSampleArgs = {
  input: AddSamplePhotosToSampleInput;
};


export type MutationSetSamplePhotosOnSampleArgs = {
  input: SetSamplePhotosOnSampleInput;
};


export type MutationCreateOneSampleArgs = {
  input: CreateOneSampleInput;
};


export type MutationCreateManySamplesArgs = {
  input: CreateManySamplesInput;
};


export type MutationUpdateOneSampleArgs = {
  input: UpdateOneSampleInput;
};


export type MutationUpdateManySamplesArgs = {
  input: UpdateManySamplesInput;
};


export type MutationDeleteOneSampleArgs = {
  input: DeleteOneSampleInput;
};


export type MutationDeleteManySamplesArgs = {
  input: DeleteManySamplesInput;
};


export type MutationSetMeshOnSampleMeshArgs = {
  input: SetMeshOnSampleMeshInput;
};


export type MutationSetSampleOnSampleMeshArgs = {
  input: SetSampleOnSampleMeshInput;
};


export type MutationCreateOneSampleMeshArgs = {
  input: CreateOneSampleMeshInput;
};


export type MutationCreateManySampleMeshesArgs = {
  input: CreateManySampleMeshesInput;
};


export type MutationUpdateOneSampleMeshArgs = {
  input: UpdateOneSampleMeshInput;
};


export type MutationUpdateManySampleMeshesArgs = {
  input: UpdateManySampleMeshesInput;
};


export type MutationDeleteOneSampleMeshArgs = {
  input: DeleteOneSampleMeshInput;
};


export type MutationDeleteManySampleMeshesArgs = {
  input: DeleteManySampleMeshesInput;
};


export type MutationSetSampleOnSamplePhotoArgs = {
  input: SetSampleOnSamplePhotoInput;
};


export type MutationCreateOneSamplePhotoArgs = {
  input: CreateOneSamplePhotoInput;
};


export type MutationAddUsersToUserTypeArgs = {
  input: AddUsersToUserTypeInput;
};


export type MutationSetUsersOnUserTypeArgs = {
  input: SetUsersOnUserTypeInput;
};


export type MutationCreateOneUserTypeArgs = {
  input: CreateOneUserTypeInput;
};


export type MutationCreateManyUserTypesArgs = {
  input: CreateManyUserTypesInput;
};


export type MutationUpdateOneUserTypeArgs = {
  input: UpdateOneUserTypeInput;
};


export type MutationUpdateManyUserTypesArgs = {
  input: UpdateManyUserTypesInput;
};


export type MutationDeleteOneUserTypeArgs = {
  input: DeleteOneUserTypeInput;
};


export type MutationDeleteManyUserTypesArgs = {
  input: DeleteManyUserTypesInput;
};


export type MutationSetUserOnTicketArgs = {
  input: SetUserOnTicketInput;
};


export type MutationAddTicketCommentsToTicketArgs = {
  input: AddTicketCommentsToTicketInput;
};


export type MutationSetTicketCommentsOnTicketArgs = {
  input: SetTicketCommentsOnTicketInput;
};


export type MutationAddTicketFilesToTicketArgs = {
  input: AddTicketFilesToTicketInput;
};


export type MutationSetTicketFilesOnTicketArgs = {
  input: SetTicketFilesOnTicketInput;
};


export type MutationCreateOneTicketArgs = {
  input: CreateOneTicketInput;
};


export type MutationSetUserOnTicketCommentArgs = {
  input: SetUserOnTicketCommentInput;
};


export type MutationAddTicketFilesToTicketCommentArgs = {
  input: AddTicketFilesToTicketCommentInput;
};


export type MutationSetTicketFilesOnTicketCommentArgs = {
  input: SetTicketFilesOnTicketCommentInput;
};


export type MutationCreateOneTicketCommentArgs = {
  input: CreateOneTicketCommentInput;
};


export type MutationCreateOneIssueArgs = {
  input: CreateOneIssueInput;
};

export type SetTypeOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type AddCheckinSampleStatesToUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetCheckinSampleStatesOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type CreateOneUserInput = {
  /** The record to create */
  user: CreateUser;
};

export type CreateUser = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserTypeInput>;
  stats?: Maybe<DashUserStatInput>;
};

export type CreateManyUsersInput = {
  /** Array of records to create */
  users: Array<CreateUser>;
};

export type UpdateOneUserInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateUser;
};

export type UpdateUser = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserTypeInput>;
  stats?: Maybe<DashUserStatInput>;
};

export type UpdateManyUsersInput = {
  /** Filter used to find fields to update */
  filter: UserUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateUser;
};

export type UserUpdateFilter = {
  and?: Maybe<Array<UserUpdateFilter>>;
  or?: Maybe<Array<UserUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type DeleteOneUserInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteManyUsersInput = {
  /** Filter to find records to delete */
  filter: UserDeleteFilter;
};

export type UserDeleteFilter = {
  and?: Maybe<Array<UserDeleteFilter>>;
  or?: Maybe<Array<UserDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type SetSampleStateTypeOnSampleStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCheckinUserOnSampleStateInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type CreateOneSampleStateInput = {
  /** The record to create */
  sampleState: CreateSampleState;
};

export type CreateSampleState = {
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type CreateManySampleStatesInput = {
  /** Array of records to create */
  sampleStates: Array<CreateSampleState>;
};

export type UpdateOneSampleStateInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSampleState;
};

export type UpdateSampleState = {
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  sampleStateTypeId?: Maybe<Scalars['Float']>;
};

export type UpdateManySampleStatesInput = {
  /** Filter used to find fields to update */
  filter: SampleStateUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSampleState;
};

export type SampleStateUpdateFilter = {
  and?: Maybe<Array<SampleStateUpdateFilter>>;
  or?: Maybe<Array<SampleStateUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  sampleStateTypeId?: Maybe<NumberFieldComparison>;
};

export type DeleteOneSampleStateInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteManySampleStatesInput = {
  /** Filter to find records to delete */
  filter: SampleStateDeleteFilter;
};

export type SampleStateDeleteFilter = {
  and?: Maybe<Array<SampleStateDeleteFilter>>;
  or?: Maybe<Array<SampleStateDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  sampleStateTypeId?: Maybe<NumberFieldComparison>;
};

export type SetCommercialOnBrandInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetStylistOnBrandInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type CreateOneBrandInput = {
  /** The record to create */
  brand: CreateBrand;
};

export type CreateBrand = {
  name: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
};

export type CreateManyBrandsInput = {
  /** Array of records to create */
  brands: Array<CreateBrand>;
};

export type UpdateOneBrandInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateBrand;
};

export type UpdateBrand = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  commercial?: Maybe<UserInput>;
  stylist?: Maybe<UserInput>;
};

export type UpdateManyBrandsInput = {
  /** Filter used to find fields to update */
  filter: BrandUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateBrand;
};

export type BrandUpdateFilter = {
  and?: Maybe<Array<BrandUpdateFilter>>;
  or?: Maybe<Array<BrandUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
};

export type DeleteOneBrandInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteManyBrandsInput = {
  /** Filter to find records to delete */
  filter: BrandDeleteFilter;
};

export type BrandDeleteFilter = {
  and?: Maybe<Array<BrandDeleteFilter>>;
  or?: Maybe<Array<BrandDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
};

export type SetUserTypeOnChecklistInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type AddChecklistItemsToChecklistInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetChecklistItemsOnChecklistInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetInspectedByOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetBrandOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSupplierOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshSupplierOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetLocationOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type AddMeshConsumptionsToMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMeshConsumptionsOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddSampleMeshesToMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSampleMeshesOnMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMeshConsumptionsFromMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSampleMeshesFromMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type UpdateOneMeshInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMesh;
};

export type UpdateMesh = {
  purchaseOrder?: Maybe<Scalars['String']>;
  purchaseOrderType?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['ID']>;
  supplierDocument?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['ID']>;
  meshSupplier?: Maybe<Scalars['ID']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  meshKind?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived?: Maybe<Scalars['Float']>;
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived?: Maybe<Scalars['Float']>;
  washType?: Maybe<Scalars['String']>;
  widthBeforeWash?: Maybe<Scalars['Float']>;
  widthAfterWash?: Maybe<Scalars['Float']>;
  widthShrinkageObservations?: Maybe<Scalars['String']>;
  lengthBeforeWash?: Maybe<Scalars['Float']>;
  lengthAfterWash?: Maybe<Scalars['Float']>;
  lengthShrinkageObservations?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spiralityObservations?: Maybe<Scalars['String']>;
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived?: Maybe<Scalars['Float']>;
  receptionDate?: Maybe<Scalars['DateTime']>;
  inspectionDate?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Scalars['ID']>;
};

export type UpdateManyMeshesInput = {
  /** Filter used to find fields to update */
  filter: MeshUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMesh;
};

export type MeshUpdateFilter = {
  and?: Maybe<Array<MeshUpdateFilter>>;
  or?: Maybe<Array<MeshUpdateFilter>>;
  id?: Maybe<NumberFieldComparison>;
  supplierDocument?: Maybe<StringFieldComparison>;
  meshSupplierDocument?: Maybe<StringFieldComparison>;
  purchaseOrder?: Maybe<StringFieldComparison>;
  meshKind?: Maybe<StringFieldComparison>;
  composition?: Maybe<StringFieldComparison>;
  color?: Maybe<StringFieldComparison>;
  colorObservations?: Maybe<StringFieldComparison>;
  widthRequired?: Maybe<NumberFieldComparison>;
  widthReceived?: Maybe<NumberFieldComparison>;
  weightRequired?: Maybe<NumberFieldComparison>;
  weightReceived?: Maybe<NumberFieldComparison>;
  washType?: Maybe<StringFieldComparison>;
  spiralityDeviation?: Maybe<NumberFieldComparison>;
  quantityUnit?: Maybe<StringFieldComparison>;
  quantitySent?: Maybe<NumberFieldComparison>;
  quantityReceived?: Maybe<NumberFieldComparison>;
  currentQuantity?: Maybe<NumberFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type CreateOneMeshInput = {
  mesh: CreateMesh;
};

export type CreateMesh = {
  purchaseOrder: Scalars['String'];
  purchaseOrderType: Scalars['String'];
  brand: Scalars['ID'];
  supplierDocument: Scalars['String'];
  supplier: Scalars['ID'];
  meshSupplier?: Maybe<Scalars['ID']>;
  meshSupplierDocument?: Maybe<Scalars['String']>;
  meshKind: Scalars['String'];
  composition: Scalars['String'];
  color: Scalars['String'];
  colorObservations?: Maybe<Scalars['String']>;
  widthRequired?: Maybe<Scalars['Float']>;
  widthReceived: Scalars['Float'];
  weightRequired?: Maybe<Scalars['Float']>;
  weightReceived: Scalars['Float'];
  washType?: Maybe<Scalars['String']>;
  widthBeforeWash?: Maybe<Scalars['Float']>;
  widthAfterWash?: Maybe<Scalars['Float']>;
  widthShrinkageObservations?: Maybe<Scalars['String']>;
  lengthBeforeWash?: Maybe<Scalars['Float']>;
  lengthAfterWash?: Maybe<Scalars['Float']>;
  lengthShrinkageObservations?: Maybe<Scalars['String']>;
  spiralityDeviation?: Maybe<Scalars['Float']>;
  spiralityObservations?: Maybe<Scalars['String']>;
  quantityUnit: Scalars['String'];
  quantitySent?: Maybe<Scalars['Float']>;
  quantityReceived: Scalars['Float'];
  receptionDate: Scalars['DateTime'];
  inspectionDate: Scalars['DateTime'];
  location?: Maybe<Scalars['ID']>;
};

export type SetUserOnMeshConsumptionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMeshOnMeshConsumptionInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type UpdateOneMeshConsumptionInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateMeshConsumption;
};

export type UpdateMeshConsumption = {
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  purpose?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateManyMeshConsumptionsInput = {
  /** Filter used to find fields to update */
  filter: MeshConsumptionUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateMeshConsumption;
};

export type MeshConsumptionUpdateFilter = {
  and?: Maybe<Array<MeshConsumptionUpdateFilter>>;
  or?: Maybe<Array<MeshConsumptionUpdateFilter>>;
  id?: Maybe<NumberFieldComparison>;
  quantity?: Maybe<NumberFieldComparison>;
  purpose?: Maybe<StringFieldComparison>;
};

export type CreateOneMeshConsumptionInput = {
  meshConsumption: CreateMeshConsumption;
};

export type CreateMeshConsumption = {
  quantity: Scalars['Float'];
  purpose: Scalars['String'];
  mesh: Scalars['Float'];
};

export type DeleteOneMeshConsumptionInput = {
  id: Scalars['ID'];
};

export type CreateOneSampleTypeInput = {
  /** The record to create */
  sampleType: CreateSampleType;
};

export type CreateSampleType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type CreateManySampleTypesInput = {
  /** Array of records to create */
  sampleTypes: Array<CreateSampleType>;
};

export type UpdateOneSampleTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSampleType;
};

export type UpdateSampleType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type UpdateManySampleTypesInput = {
  /** Filter used to find fields to update */
  filter: SampleTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSampleType;
};

export type SampleTypeUpdateFilter = {
  and?: Maybe<Array<SampleTypeUpdateFilter>>;
  or?: Maybe<Array<SampleTypeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type DeleteOneSampleTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteManySampleTypesInput = {
  /** Filter to find records to delete */
  filter: SampleTypeDeleteFilter;
};

export type SampleTypeDeleteFilter = {
  and?: Maybe<Array<SampleTypeDeleteFilter>>;
  or?: Maybe<Array<SampleTypeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type SetCurrentStateOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetConfectionOverseerOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSampleTypeOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetBrandOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type AddSamplePhotosToSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSamplePhotosOnSampleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type CreateOneSampleInput = {
  /** The record to create */
  sample: CreateSample;
};

export type CreateSample = {
  id?: Maybe<Scalars['Float']>;
  orderReference?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  foreseenDate?: Maybe<Scalars['DateTime']>;
  intendedDate?: Maybe<Scalars['DateTime']>;
  sketch?: Maybe<Scalars['String']>;
  fastTrack?: Maybe<Scalars['Boolean']>;
  hasMesh?: Maybe<Scalars['Boolean']>;
  hasMeshUpdatedAt?: Maybe<Scalars['String']>;
  shippingDate?: Maybe<Scalars['String']>;
  hasConfectionAccessories?: Maybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  hasPackingAccessories?: Maybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  decorationsReceivedAt?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  foreseenDateExceededReason?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type CreateManySamplesInput = {
  /** Array of records to create */
  samples: Array<CreateSample>;
};

export type UpdateOneSampleInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSample;
};

export type UpdateSample = {
  id?: Maybe<Scalars['Float']>;
  orderReference?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  foreseenDate?: Maybe<Scalars['DateTime']>;
  intendedDate?: Maybe<Scalars['DateTime']>;
  sketch?: Maybe<Scalars['String']>;
  fastTrack?: Maybe<Scalars['Boolean']>;
  hasMesh?: Maybe<Scalars['Boolean']>;
  hasMeshUpdatedAt?: Maybe<Scalars['String']>;
  shippingDate?: Maybe<Scalars['String']>;
  hasConfectionAccessories?: Maybe<Scalars['Boolean']>;
  hasConfectionAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  hasPackingAccessories?: Maybe<Scalars['Boolean']>;
  hasPackingAccessoriesUpdatedAt?: Maybe<Scalars['String']>;
  decorationsReceivedAt?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  foreseenDateExceededReason?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type UpdateManySamplesInput = {
  /** Filter used to find fields to update */
  filter: SampleUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSample;
};

export type SampleUpdateFilter = {
  and?: Maybe<Array<SampleUpdateFilter>>;
  or?: Maybe<Array<SampleUpdateFilter>>;
  orderReference?: Maybe<NumberFieldComparison>;
  isVirtual?: Maybe<BooleanFieldComparison>;
  quantity?: Maybe<NumberFieldComparison>;
};

export type DeleteOneSampleInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteManySamplesInput = {
  /** Filter to find records to delete */
  filter: SampleDeleteFilter;
};

export type SampleDeleteFilter = {
  and?: Maybe<Array<SampleDeleteFilter>>;
  or?: Maybe<Array<SampleDeleteFilter>>;
  orderReference?: Maybe<NumberFieldComparison>;
  isVirtual?: Maybe<BooleanFieldComparison>;
  quantity?: Maybe<NumberFieldComparison>;
};

export type SetMeshOnSampleMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSampleOnSampleMeshInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type CreateOneSampleMeshInput = {
  /** The record to create */
  sampleMesh: CreateSampleMesh;
};

export type CreateSampleMesh = {
  mesh: Scalars['Float'];
  sample: Scalars['Float'];
};

export type CreateManySampleMeshesInput = {
  /** Array of records to create */
  sampleMeshes: Array<CreateSampleMesh>;
};

export type UpdateOneSampleMeshInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateSampleMesh;
};

export type UpdateSampleMesh = {
  id?: Maybe<Scalars['Float']>;
};

export type UpdateManySampleMeshesInput = {
  /** Filter used to find fields to update */
  filter: SampleMeshUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateSampleMesh;
};

export type SampleMeshUpdateFilter = {
  and?: Maybe<Array<SampleMeshUpdateFilter>>;
  or?: Maybe<Array<SampleMeshUpdateFilter>>;
  id?: Maybe<NumberFieldComparison>;
};

export type DeleteOneSampleMeshInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteManySampleMeshesInput = {
  /** Filter to find records to delete */
  filter: SampleMeshDeleteFilter;
};

export type SampleMeshDeleteFilter = {
  and?: Maybe<Array<SampleMeshDeleteFilter>>;
  or?: Maybe<Array<SampleMeshDeleteFilter>>;
  id?: Maybe<NumberFieldComparison>;
};

export type SetSampleOnSamplePhotoInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type CreateOneSamplePhotoInput = {
  samplePhoto: CreateSamplePhoto;
};

export type CreateSamplePhoto = {
  upload: Scalars['Upload'];
  sample: Scalars['ID'];
};


export type AddUsersToUserTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetUsersOnUserTypeInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type CreateOneUserTypeInput = {
  /** The record to create */
  userType: CreateUserType;
};

export type CreateUserType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  todo?: Maybe<Array<SampleInputType>>;
  doing?: Maybe<Array<SampleInputType>>;
  done?: Maybe<Array<SampleInputType>>;
  todoCount?: Maybe<Scalars['Int']>;
  doingCount?: Maybe<Scalars['Int']>;
  doneCount?: Maybe<Scalars['Int']>;
};

export type CreateManyUserTypesInput = {
  /** Array of records to create */
  userTypes: Array<CreateUserType>;
};

export type UpdateOneUserTypeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateUserType;
};

export type UpdateUserType = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  todo?: Maybe<Array<SampleInputType>>;
  doing?: Maybe<Array<SampleInputType>>;
  done?: Maybe<Array<SampleInputType>>;
  todoCount?: Maybe<Scalars['Int']>;
  doingCount?: Maybe<Scalars['Int']>;
  doneCount?: Maybe<Scalars['Int']>;
};

export type UpdateManyUserTypesInput = {
  /** Filter used to find fields to update */
  filter: UserTypeUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateUserType;
};

export type UserTypeUpdateFilter = {
  and?: Maybe<Array<UserTypeUpdateFilter>>;
  or?: Maybe<Array<UserTypeUpdateFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type DeleteOneUserTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteManyUserTypesInput = {
  /** Filter to find records to delete */
  filter: UserTypeDeleteFilter;
};

export type UserTypeDeleteFilter = {
  and?: Maybe<Array<UserTypeDeleteFilter>>;
  or?: Maybe<Array<UserTypeDeleteFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type SetUserOnTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type AddTicketCommentsToTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTicketCommentsOnTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddTicketFilesToTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTicketFilesOnTicketInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type CreateOneTicketInput = {
  ticket: CreateTicket;
};

export type CreateTicket = {
  name: Scalars['String'];
  description: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  ticketFiles?: Maybe<Array<Scalars['Upload']>>;
};

export type SetUserOnTicketCommentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type AddTicketFilesToTicketCommentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetTicketFilesOnTicketCommentInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type CreateOneTicketCommentInput = {
  ticketComment: CreateTicketComment;
};

export type CreateTicketComment = {
  content: Scalars['String'];
  ticket: Scalars['Int'];
  ticketFiles?: Maybe<Array<Scalars['Upload']>>;
};

export type CreateOneIssueInput = {
  issue: CreateIssue;
};

export type CreateIssue = {
  reference: Scalars['String'];
  order: Scalars['String'];
  description: Scalars['String'];
  sample: Scalars['String'];
};

export type UserStatFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'color' | 'avatar'>
  & { stats: (
    { __typename?: 'DashUserStat' }
    & { week: (
      { __typename?: 'DashPeriodStat' }
      & Pick<DashPeriodStat, 'reference' | 'current'>
    ), day: (
      { __typename?: 'DashPeriodStat' }
      & Pick<DashPeriodStat, 'reference' | 'current'>
    ) }
  ) }
);

export type UserTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserTypeQuery = (
  { __typename?: 'Query' }
  & { userType?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name' | 'todoCount' | 'doingCount' | 'doneCount'>
    & { users: Array<(
      { __typename?: 'User' }
      & UserStatFragment
    )>, todo: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'orderReference' | 'clientReference' | 'foreseenDate' | 'sketch' | 'fastTrack' | 'hasMesh' | 'hasConfectionAccessories' | 'hasPackingAccessories'>
      & { brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'id' | 'name'>
        & { commercial?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'name'>
        )>, stylist?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'name' | 'color' | 'avatar'>
        )> }
      ), confectionOverseer?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name' | 'color' | 'avatar'>
      )>, sampleType: (
        { __typename?: 'SampleType' }
        & Pick<SampleType, 'id' | 'name'>
      ) }
    )>, doing: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'orderReference' | 'clientReference' | 'foreseenDate' | 'sketch' | 'fastTrack' | 'hasMesh' | 'hasConfectionAccessories' | 'hasPackingAccessories'>
      & { currentState: (
        { __typename?: 'SampleState' }
        & Pick<SampleState, 'createdAt'>
      ), brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'id' | 'name'>
        & { commercial?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'name'>
        )>, stylist?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'name' | 'color' | 'avatar'>
        )> }
      ), confectionOverseer?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name' | 'color' | 'avatar'>
      )>, sampleType: (
        { __typename?: 'SampleType' }
        & Pick<SampleType, 'id' | 'name'>
      ) }
    )>, done: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'orderReference' | 'clientReference' | 'foreseenDate' | 'sketch' | 'fastTrack' | 'hasMesh'>
      & { brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'id' | 'name'>
        & { commercial?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'name'>
        )>, stylist?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'name' | 'color' | 'avatar'>
        )> }
      ), confectionOverseer?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name' | 'color' | 'avatar'>
      )>, sampleType: (
        { __typename?: 'SampleType' }
        & Pick<SampleType, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export const UserStatFragmentDoc = gql`
    fragment userStat on User {
  id
  name
  color
  avatar
  stats {
    week {
      reference
      current
    }
    day {
      reference
      current
    }
  }
}
    `;
export const UserTypeDocument = gql`
    query userType($id: ID!) {
  userType(id: $id) {
    id
    name
    todoCount
    doingCount
    doneCount
    users {
      ...userStat
    }
    todo {
      id
      orderReference
      clientReference
      foreseenDate
      sketch
      fastTrack
      hasMesh
      hasConfectionAccessories
      hasPackingAccessories
      brand {
        id
        name
        commercial {
          name
        }
        stylist {
          name
          color
          avatar
        }
      }
      confectionOverseer {
        name
        color
        avatar
      }
      sampleType {
        id
        name
      }
    }
    doing {
      id
      orderReference
      clientReference
      foreseenDate
      sketch
      fastTrack
      hasMesh
      hasConfectionAccessories
      hasPackingAccessories
      currentState {
        createdAt
      }
      brand {
        id
        name
        commercial {
          name
        }
        stylist {
          name
          color
          avatar
        }
      }
      confectionOverseer {
        name
        color
        avatar
      }
      sampleType {
        id
        name
      }
    }
    done {
      id
      orderReference
      clientReference
      foreseenDate
      sketch
      fastTrack
      hasMesh
      brand {
        id
        name
        commercial {
          name
        }
        stylist {
          name
          color
          avatar
        }
      }
      confectionOverseer {
        name
        color
        avatar
      }
      sampleType {
        id
        name
      }
    }
  }
}
    ${UserStatFragmentDoc}`;

/**
 * __useUserTypeQuery__
 *
 * To run a query within a React component, call `useUserTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserTypeQuery, UserTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<UserTypeQuery, UserTypeQueryVariables>(UserTypeDocument, baseOptions);
      }
export function useUserTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserTypeQuery, UserTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserTypeQuery, UserTypeQueryVariables>(UserTypeDocument, baseOptions);
        }
export type UserTypeQueryHookResult = ReturnType<typeof useUserTypeQuery>;
export type UserTypeLazyQueryHookResult = ReturnType<typeof useUserTypeLazyQuery>;
export type UserTypeQueryResult = ApolloReactCommon.QueryResult<UserTypeQuery, UserTypeQueryVariables>;