import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import DepartmentDashboard from './components/DepartmentDashboard/DepartmentDashboard';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Logout from './components/Logout/Logout';
import client from "./services/Client";
import theme from './theme';

function App() {
    return (
        <ApolloProvider client={client}>
            <Router>
                <MuiThemeProvider theme={theme}>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/logout" component={Logout} />
                    <Route exact path="/cad" component={DepartmentDashboard} />
                    <Route exact path="/cutting" component={DepartmentDashboard} />
                    <Route exact path="/confection" component={DepartmentDashboard} />
                </MuiThemeProvider>
            </Router>
        </ApolloProvider>
    );
}

export default App;
