import Grid from '@material-ui/core/Grid';
import React, { FC } from 'react';
import { Maybe } from '../../generated/graphql';
import { mediaUrl } from '../../services/Utils';
import styles from './Avatar.module.scss';

type AvatarProps = {
    name?: Maybe<string>,
    color?: Maybe<string>,
    avatar?: Maybe<string>
    width?: number
    height?: number
}

const Avatar: FC<AvatarProps> = (props) => {
    const {
        name = "",
        color = '#ccc',
        avatar,
        width = 33,
        height = 33,
    } = props

    if (avatar && avatar !== 'default/user_avatar_740x781.png') {
        const url = mediaUrl(avatar);
        return (
            <Grid container className={styles.Avatar} style={{ backgroundColor: color!, backgroundImage: `url(${url})`, width, height }} />
        )
    }
    return (
        <Grid container className={styles.Avatar} style={{ backgroundColor: color!, width, height }}>
            <Grid>{name && name.charAt(0)}</Grid>
        </Grid >
    )
}


export default Avatar;
