import { useMutation } from '@apollo/react-hooks';
import { Card, CardContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import Config from '../../services/Config';
import { useChangeHandler, useUserRedirection } from '../../services/Hooks';
import { LOGIN_USER } from '../../services/Local';
import styles from './Login.module.scss';

interface LoginData {
    email: string,
    password: string
}

const Login = () => {

    const [user, setUser] = useState<LoginData>({ email: "", password: "" });
    const [validationError, setValidationError] = useState({ message: '' });
    const changeHandler = useChangeHandler(user, setUser);
    const [loginUser] = useMutation(LOGIN_USER)

    useUserRedirection();


    const handleSubmit = (event: any) => {
        event.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: user.email, password: user.password })
        };

        fetch(Config.AUTH_ENDPOINT, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.token) {
                    loginUser({ variables: { token: data.token } })
                } else {
                    setValidationError({ message: 'Não foi possível iniciar sessão.' });
                }
            });
    };

    return (
        <Grid container justify="center" alignItems="center" className={styles.Login}>
            <Grid item xs={3}>
                <Grid container direction="column">
                    <h1>MyPedrosa</h1>
                    <Card>
                        <CardContent>
                            <form onSubmit={handleSubmit} className={styles.LoginForm}>
                                <div data-cy="errors" className={styles.ValidationError}>
                                    {validationError && validationError.message}
                                </div>
                                <TextField
                                    data-cy="email"
                                    name="email"
                                    label="Email"
                                    required
                                    fullWidth
                                    autoFocus
                                    onChange={changeHandler}
                                />
                                <TextField
                                    data-cy="password"
                                    type="password"
                                    name="password"
                                    label="Password"
                                    required
                                    fullWidth
                                    onChange={changeHandler}
                                />
                                <Grid container justify="center">
                                    <Button
                                        data-cy="submit"
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                    >
                                        iniciar sessão
				    </Button>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Login;
