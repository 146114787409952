import { useMutation } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import { useGuestRedirection } from '../../services/Hooks';
import styles from './Logout.module.scss';
import { LOGOUT_USER } from '../../services/Local';

const Logout = () => {
    useGuestRedirection();

    const [logoutUser] = useMutation(LOGOUT_USER);

    const handleSubmit = () => {
        logoutUser();
    };

    return (

        <Grid container justify="center" alignItems="center" className={styles.Login}>
            <Grid item xs={3}>
                <Grid container direction="column">
                    <h1>MyPedrosa</h1>
                    <Card>
                        <CardContent>
                            <p>Para encerrar a sessão clique no botão em baixo. Será redirecionado para a página de login.</p>
                            <Grid container justify="center">
                                <Button
                                    data-cy="logout_submit"
                                    color='primary'
                                    variant='contained'
                                    onClick={handleSubmit}
                                    className={styles.Submit}
                                >
                                    Encerrar Sessão
				</Button>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Logout;
