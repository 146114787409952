import Config from "./Config";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from "apollo-link-http";

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('auth.token');
    const prefix = Config.JWT_AUTH_HEADER_PREFIX;
    return {
        headers: {
            ...headers,
            'authorization': token ? `${prefix} ${token}` : "",
        }
    };
});

const httpLink = createHttpLink({
    uri: Config.GRAPHQL_API
});

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
    resolvers: {
        Mutation: {
            loginUser: (_root, variables, { cache }) => {
                const { token } = variables;
                cache.writeData({ data: { token } });
                localStorage.setItem('auth.token', token);
                return true
            },
            logoutUser: (_root, _, { cache, }) => {
                localStorage.removeItem('auth.token');
                cache.reset()
                return null;
            }
        },
    }
});

export default client;
