import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import React from 'react';
import Moment from 'react-moment';
import { Sample } from '../../generated/graphql';
import { diffDateNow, mediaUrl } from '../../services/Utils';
import Avatar from '../Avatar/Avatar';
import styles from './WorkItem.module.scss';

const WorkItem = ({ item, department }: { item: Sample, department: string }) => {
    const url = mediaUrl(item.sketch!);
    const classes = [styles.WorkItem];
    const user = department === 'CONFECTION' ? item.confectionOverseer : item.brand?.stylist
    const sampleColor = user?.color ? user.color : '#ccc'

    if (item?.currentState?.createdAt) {
        const diff = diffDateNow(item.currentState.createdAt)
        if (diff < (2 * 60 * 1000)) {
            classes.push(styles.Changed);
        }
    }

    if (item.fastTrack) {
        classes.push(styles.FastTrack);
    }

    return (
        <Grid container direction="row" justify="space-between" className={classNames(classes)}>
            <Grid item xs={4} className={styles.Sketch} style={{ backgroundImage: `url(${url})` }}>
            </Grid>
            <Grid item xs={6} className={styles.WorkItemDetails}>
                <p className={styles.Sample} style={{ backgroundColor: sampleColor }}>{item.orderReference}</p>
                <p className={styles.Brand}>{item?.brand?.name}</p>
                <p className={styles.SampleType}>{item?.sampleType?.name}</p>

                <Grid container direction="row" alignItems="center" justify="center">
                    <Grid container item xs={2} direction="column" alignItems="center">
                    </Grid>
                    <Grid container item xs={8} direction="column" alignItems="center">
                        <p className={classNames(styles.UntilDays, { [styles.Outdated]: new Date(item.foreseenDate) < new Date() })}>
                            {item.foreseenDate ? <Moment diff={new Date()} unit="days">
                                {item.foreseenDate}
                            </Moment> : '-'}
                        </p>
                        <p className={styles.UntilMessage}>dias até envio</p>
                    </Grid>
                    <Grid item xs={2}>
                        <Avatar name={user?.name} avatar={user?.avatar} color={user?.color} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
};

export default WorkItem;
