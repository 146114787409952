import Grid from '@material-ui/core/Grid';
import React, { FC } from 'react';
import WorkItem from '../WorkItem/WorkItem';
import styles from './WorkList.module.scss';

const More = ({ count }: { count?: number }) => {
    if (!count) {
        return null;
    }
    return (
        <div className={styles.More}>
            + {count}
        </div>
    );
};

type WorkListProps = {
    name: string,
    color: string,
    workItems: any,
    count?: number,
    department: string
}

const WorkList: FC<WorkListProps> = (props) => {
    const { name, color, workItems, count, department } = props

    return (
        <Grid container direction="column" alignItems="center" className={styles.WorkList} style={{ borderTop: `5px solid ${color} ` }}>
            <h3>{name}</h3>
            {workItems.map((sample: any) => <WorkItem key={sample.id} item={sample} department={department} />)}
            <More count={count && count - workItems.length} />
        </Grid>
    );
};

export default WorkList;
