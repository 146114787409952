const Config = {
    // @ts-ignore
    GRAPHQL_API: window._env_ ? window._env_.GRAPHQL_API : '',
    // @ts-ignore
    MEDIA_URL: window._env_ ? window._env_.MEDIA_URL : '',
    JWT_AUTH_HEADER_PREFIX: "Bearer",
    // @ts-ignore
    AUTH_ENDPOINT: window._env_ ? window._env_.GRAPHQL_API + "/auth/login/" : '',
};

export default Config;
