import { Card } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGuestRedirection } from '../../services/Hooks';
import styles from './Home.module.scss';

const CardBoard = ({ route, label }: { route: any, label: any }) => {
    return (
        <Card className={styles.CardBoard}>
            <Link to={route}>
                <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                    <h3>{label}</h3>
                </Grid>
            </Link>
        </Card>
    );
};

const Home = () => {

    useGuestRedirection();

    /* const dashboards = [
     *     { id: 1, route: '/commercial', label: 'Comercial' },
     *     { id: 2, route: '/cad', label: 'Modelação' },
     *     { id: 3, route: '/cutting', label: 'Corte' },
     *     { id: 5, route: '/ornaments', label: 'Ornamentos' },
     *     { id: 4, route: '/confection', label: 'Confeção' },
     *     { id: 6, route: '/finishings', label: 'Acabamentos' },
     *     { id: 7, route: '/control', label: 'Controlo' }
     * ]; */

    const dashboards = [
        { id: 2, route: '/cad', label: 'CAD' },
        { id: 3, route: '/cutting', label: 'Corte' },
        { id: 4, route: '/confection', label: 'Confeção' }
    ];


    return (
        <Grid container justify="center" alignItems="center" className={styles.Home}>
            {dashboards.map(dashboard => <CardBoard key={dashboard.id} {...dashboard} />)}
        </Grid>
    );
};

export default Home;
