import Grid from '@material-ui/core/Grid';
import React from 'react';
import logo from '../../assets/images/logo.png';
import { useUserTypeQuery } from '../../generated/graphql';
import { useGuestRedirection } from '../../services/Hooks';
import { getDeparmentName, parseDepartmentUrl } from '../../services/Utils';
import DashboardSummaryStats from '../DashboardSummaryStats/DashboardSummaryStats';
import Loading from '../Loading/Loading';
import WorkList from '../WorkList/WorkList';
import styles from './DepartmentDashboard.module.scss';

const DepartmentDashboard = (props: any) => {

    useGuestRedirection();

    const id = parseDepartmentUrl(props.match.url)

    const config = { variables: { id }, pollInterval: 3000 };
    const { data, loading } = useUserTypeQuery(config)

    if (loading) {
        return <Loading />;
    }

    const departmentName = getDeparmentName(id)

    return (
        <Grid container className={styles.DepartmentDashboard}>
            <Grid container justify="space-around" alignItems="center">
                <Grid item xs={3} className={styles.Title}><span style={{ fontWeight: 300 }}>MYPEDROSA DASHBOARD</span> - <b>{departmentName}</b></Grid>
                <Grid item xs={3}>
                    <DashboardSummaryStats users={data?.userType?.users} />
                </Grid>
                <Grid item xs={3} style={{ textAlign: 'right' }}>
                    <img src={logo} style={{ width: '50px' }} alt="Pedrosa & Rodrigues" />
                </Grid>
            </Grid>

            <Grid container justify="space-evenly" alignItems="center" className={styles.Worklists}>

                <Grid item xs={3} className={styles.WorkList}>
                    <WorkList name="em espera" color="#FFCC80" workItems={data?.userType?.todo} count={data?.userType?.todoCount} department={id} />
                </Grid>
                <Grid item xs={3} className={styles.WorkList}>
                    <WorkList name="em curso" color="#7AB0F9" workItems={data?.userType?.doing} count={data?.userType?.doingCount} department={id} />
                </Grid>
                <Grid item xs={3} className={styles.WorkList}>
                    <WorkList name="feito" color="#82C786" workItems={data?.userType?.done} count={data?.userType?.doneCount} department={id} />
                </Grid>

            </Grid>
        </Grid >
    );
};


export default DepartmentDashboard;
